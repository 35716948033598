import React from 'react';


import Modal from "../../../Modals/Modal";
import HTMLContent from "components/HTMLContent"
import {connectAll} from "../swapHelpers";
import {URLS} from 'urls'
import * as validation from 'validation'

import "./reminder-box.css"
import {FFInput} from "../../../shared/finalFormFields";
import {getSwapSubmitFn} from "../swapHelpers";
import {Form} from "react-final-form";
import iconLegal from "front_end/icons/icon-legal.svg"


let ConditionsForm = props => {
    const {valid, pristine, handleSubmit, onShowModal} = props
    return (
        <form onSubmit={handleSubmit}>
            <FFInput name="termsAccepted"
                     type="checkbox"
                     validate={validation.validateIsTrue}
                     label={<span>Jeg accepterer <a onClick={(e) => {
                         e.stopPropagation();
                         onShowModal(e)
                     }}> vilkårene </a> og forpligtiger mig til at bruge Unik Boligssalgs Bytteaftale til 14.995,- når jeg indgår i en byttehandel via sitet</span>
                     }/>


            <button className="button" disabled={!valid || pristine}>Se dine boligmatch</button>

        </form>
    )
}


let ConditionsModal = ({show, onClose, data}) => {
    return (
        <Modal show={show} onClose={onClose}>
            <HTMLContent textId="user_conditions_swap" withHeadline={true}/>
            <button className="button" onClick={onClose}>Ok - jeg er med</button>
        </Modal>
    )
}


const LegalReminder = () => (
    <div className="reminder-box">
        <span className="reminder-box-icon">
            <img src={iconLegal}/>
        </span>
        <span className="reminder-box-content">
            <div>Det er gratis at benytte Unik Boligssalgs bytteservice, men husk venligst:</div>
            <div className="reminder-box-strong">
                Du er forpligtiget til at bruge Andelshandel’s Bytteaftale når du benytter dig af denne service
            </div>
        </span>
    </div>
)


class SwapConditions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showConditionsModal: false
        }
        this.onCloseModal = this.onCloseModal.bind(this)
    }

    onCloseModal(e) {
        this.setState({showConditionsModal: false})
    }


    render() {
        if (this.props.data.loading) return <div className="section--loading"></div>
        const swapCase = this.props.data.ubUser.swapCase
        const {dispatch, history} = this.props
        {
            return (
                <div className="whishes-component">
                    <section className="section u-lightgrey ">
                        <div className="container col-s-12 col-m-6">
                            <div className="subsection">
                                <HTMLContent textId="user_conditions_swap" withHeadline={true}/>
                            </div>
                            <div className="subsection">
                                <LegalReminder/>
                            </div>
                            <div className="subsection">
                                <div className="row col-s-12 col-m-8">
                                    <Form
                                        onSubmit={getSwapSubmitFn({
                                            dispatch,
                                            nextUrl: URLS.SWAP.FRONT,
                                            history,
                                            swapCase
                                        })}
                                        keepDirtyOnReinitialize={true}>
                                        {props => <ConditionsForm
                                            {...props}
                                            onShowModal={() => this.setState({showConditionsModal: true})}
                                        />}
                                    </Form>
                                </div>
                            </div>
                            <ConditionsModal
                                show={this.state.showConditionsModal}
                                onClose={this.onCloseModal}
                            />
                        </div>
                    </section>
                </div>
            );
        }
    }
}

SwapConditions.displayName = 'SwapConditions';
SwapConditions.propTypes = {};
SwapConditions.defaultProps = {};

export default connectAll(SwapConditions)
